.intro {
	width: 100%;
	height: 100%;
	max-height: 100vh;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 9;
	transition: all 1s ease-out;
}

.intro-button {
	@apply w-32 h-8 lg:w-[8vw] lg:h-[3vw] text-sm p-2;
	background: linear-gradient(
		351deg,
		rgba(186, 16, 16, 1) 0%,
		rgba(220, 12, 21, 1) 100%
	);
	border-radius: 4px;
	color: #fff;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.38);
	position: absolute;
	bottom: 0;
	top: calc(50% - 120px);
	margin: auto;
	right: 0;
	left: 0;
	z-index: 2;
	text-align: center;
	opacity: 1;
}

.intro-left {
	@apply lg:h-[706px] h-full;
	font-size: 1rem;
	font-weight: 400;
	color: #212529;
	text-align: left;
	font-family: Arial, Helvetica, sans-serif;
	line-height: normal;
	box-sizing: border-box;
	position: relative;
	background-color: #eb6209;
	width: 50%;
	float: left;
	transform: translate(0px, 0px);
	transition: all 1s ease-out;
}

.intro-left-img {
	@apply lg:w-[15vw] w-full;
	text-align: left;
	vertical-align: middle;
	border-style: none;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	max-width: 214px;
	height: auto;
}

.intro-splitter {
	line-height: normal;
	box-sizing: border-box;
	width: 1px;
	background-color: rgba(0, 0, 0, 0.15);
	position: absolute;
	right: 0;
	top: 0;
	height: 706px;
}

.intro-right {
	@apply w-[50%] h-full lg:min-h-[706px];
	position: relative;
	background-color: #eb6209;
	float: left;
	transform: translate(0px, 0px);
	transition: all 1s ease-out;
}

.intro-right-img {
	@apply lg:max-w-[214px] lg:w-[15vw] w-full text-right;
	position: absolute;
	vertical-align: middle;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	height: auto;
}
.revealed .intro-left {
	transform: translate(calc(100% - 100vw), 0px);
}

.revealed .intro-right {
	transform: translate(calc(100vw - 100%), 0px);
}

.revealed .intro-button {
	display: none;
}

.revealed .window-effect--unicef-logo {
	display: none;
}
