.bg-section {
	background-image: url("../../../public/assets/1.png");
	background-repeat: no-repeat;
	background-color: rgb(255, 111, 46);
	width: 100%;
}

.numbers-section img {
	margin-top: -300px;
	margin-bottom: 20px;
}

.dots-container {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ccc;
	margin: 0 5px;
	cursor: pointer;
	transition: background-color 0.3s;
}

.dot.active {
	@apply bg-secondary;
}
