.unicef-banner--absolute {
	position: absolute;
	top: 0;
	@apply right-2 md:right-[40px];
}

.unicef-logo {
	background-image: url("../../../public/assets/unicef-logo.png");
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
	background-position: center;
}

.unicef-logo--small {
	@apply w-20 md:w-[7vw];
	height: auto;
}
