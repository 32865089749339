header.header {
	line-height: normal;
	box-sizing: border-box;
	display: block;
	position: relative;
	width: 100%;
	overflow: hidden;
	background: black no-repeat;
	@apply h-[77vh] md:min-h-[25rem] bg-headerMobile md:bg-header;
	background-size: cover;
}
