@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: Arial, Helvetica, sans-serif;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: "Arial Black", "Arial Bold", Arial, Helvetica, sans-serif;
	}
}

body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, "Arial", "Helvetica",
		sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
