.button-pulse {
	animation: shadow-pulse 2s infinite;
}
.button-pulse:hover {
	transition: all 0.2s ease-in-out;
	animation: none;
	cursor: pointer;
	background-color: #ff8284 !important;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	50% {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}

	50% {
		-webkit-transform: scale(1.1);
		-ms-transform: scale(1.1);
		transform: scale(1.1);
	}

	100% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}
}

.pulse {
	-webkit-animation-name: pulse;
	animation-name: pulse;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@-webkit-keyframes shadow-pulse {
	0% {
		box-shadow: 0 0 0 0px #e53d34;

		/*box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);*/
	}
	100% {
		box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
		/*box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);*/
	}
}
@keyframes shadow-pulse {
	0% {
		box-shadow: 0 0 0 0px #e53d34;

		/*box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);*/
	}
	100% {
		box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
		/*box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);*/
	}
}
