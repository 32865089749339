.slide-animation-container {
	opacity: 0;
	transform: translateY(50px);
	transition: opacity 0.8s ease, transform 0.8s ease;
}

.slide-animation {
	opacity: 1;
	transform: translateY(0);
}

.slide-element {
	width: 200px;
	height: 200px;
	background-color: #333;
	margin-bottom: 20px;
	opacity: 0;
	transform: translateY(50px);
	transition: opacity 0.8s ease, transform 0.8s ease;
}

.slide-element.slide-animation {
	opacity: 1;
	transform: translateY(0);
}
